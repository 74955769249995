import React from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import RegisterSection from "../../components/chat-channel/ChannelHeader";
import RightImageWithContentFeature from "../../components/chat-channel/RightImageWithContent";
import LeftImageWithContent from "../../components/chat-channel/LeftImageWithContent";
import ArrowRightICon from "../../components/common/Icons";
import FooterForm from "../../components/common/FooterForm";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import CenterContentHome from "../../components/common/CenterContentHeader";
import { RequestForm } from "../../components/form";

const topImage = require("../../assets/images/automate_usp/marketplace_img.png");
export const icon1 = require("../../assets/images/10-pages/03_itsupport_individual_2/Itsupport_ind_2_sec_1_icon.png");
export const icon2 = require("../../assets/images/10-pages/03_itsupport_individual_2/Itsupport_ind_2_sec_2_icon.png");
export const icon3 = require("../../assets/images/10-pages/03_itsupport_individual_2/Itsupport_ind_2_sec_3_icon.png");

const section_one = require("../../assets/images/10-pages/03_itsupport_individual_2/Itsupport_ind_2_sec_1.png");
const section_two = require("../../assets/images/automate_usp/marketplace_img.png");
const section_three = require("../../assets/images/10-pages/03_itsupport_individual_2/Itsupport_ind_2_sec_3.png");

const featureContent = [
  {
    icon: icon1,
    header: "No-code IT automation",
    content:
      "Automating stuff shouldn’t be a pain. That’s why we’ve done all the heavy lifting for you. Use Workativ’s no-code automation designer with advanced engine to deliver true automations for IT support.",
    content1: null,
    link: null,
    image: section_one,
  },
  {
    icon: icon2,
    header: "You can go live with these automations in minutes",
    content:
      "Workativ comes over 400+ pre-built automations on popular IT apps, that are ready to use from the marketplace.",
    content1: "You can go live with these automation in minutes.",
    link: null,
    image: section_two,
  },
  {
    icon: icon3,
    header: "Integrating apps in just a few clicks",
    content:
      "Our chatbot comes with pre-built and secure integrations to apps, so you only need to sign-in and connect, saving a lot of time and effort.",

    content1: null,
    link: null,
    image: section_three,
  },
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="No-Code IT Process Automation | Automate IT Support | Workativ Assistant"
        description="Chatbot with powerful IT process automation built-in to automate IT process and deliver employee IT support resolutions instantly."
        keywords={[
          "Workativ  trial",
          "workativ free trial",
          "helpdesk chatbot free trial",
          "automation",
        ]}
        ogImage={topImage}
        ogTitle="No-Code IT Process Automation | Automate IT Support | Workativ Assistant"
        ogDescription="Chatbot with powerful IT process automation built-in to automate IT process and deliver employee IT support resolutions instantly."
      />
      <Container>
        <Layout backgroundColor={"bg_header_suppport_two"} logoFor="ASSISTANT">
          <RegisterSection
            rightImage={topImage}
            backgroundColor={"bg_header_suppport_two"}
          >
            <RegisterSection.FormWithContent>
              <RegisterSection.MainHeader>
                Chatbot powered IT process automations
              </RegisterSection.MainHeader>
              <RegisterSection.SubHeader>
                Automating conversations is not enough. That’s why our platform
                comes with powerful IT process automation built in to automate
                processes and deliver resolutions in a few clicks. No coding.{" "}
              </RegisterSection.SubHeader>
            </RegisterSection.FormWithContent>
          </RegisterSection>{" "}
          <CenterContentHome>
            <CenterContentHome.Header>
              Only Workativ offers both chatbot & process automation in a single
              solution
            </CenterContentHome.Header>
          </CenterContentHome>
          {featureContent.map((feature, index) => {
            if (index % 2 !== 0) {
              return (
                <RightImageWithContentFeature
                  image={feature.image}
                  altImage={feature.header}
                >
                  <RightImageWithContentFeature.Header>
                    <div className="icon-top-img">
                      <img src={feature.icon} alt="icon" />{" "}
                    </div>
                    <h3>{feature.header}</h3>
                  </RightImageWithContentFeature.Header>
                  <RightImageWithContentFeature.SubHeader>
                    <p>{feature.content}</p>
                    {feature.content1 && <p>{feature.content1}</p>}
                  </RightImageWithContentFeature.SubHeader>
                  <RightImageWithContentFeature.Link>
                    {feature.link && (
                      <div className="card_link_landing">
                        <a href={feature.url} className="url_manipulation">
                          {feature.link}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      </div>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </RightImageWithContentFeature.Link>
                </RightImageWithContentFeature>
              );
            }
            return (
              <LeftImageWithContent
                image={feature.image}
                altImage={feature.header}
              >
                <LeftImageWithContent.HeaderIcon>
                  <div className="icon-top-img">
                    <img src={feature.icon} alt="icon" />{" "}
                  </div>
                </LeftImageWithContent.HeaderIcon>

                <LeftImageWithContent.MainHeader>
                  {feature.header}
                </LeftImageWithContent.MainHeader>
                <LeftImageWithContent.SubHeader>
                  <p>{feature.content}</p>
                  {feature.content1 && <p>{feature.content1}</p>}
                </LeftImageWithContent.SubHeader>
                <LeftImageWithContent.Link>
                  <div className="card_link_landing">
                    {feature.link && (
                      <a href={feature.link} className="url_manipulation">
                        Learn more{" "}
                        <span className="arrow_svg_link">
                          <ArrowRightICon />
                        </span>
                      </a>
                    )}
                    {feature.toDisplaySlackIcons != undefined && (
                      <SlackAndTeams />
                    )}
                  </div>
                </LeftImageWithContent.Link>
              </LeftImageWithContent>
            );
          })}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
